import CountDownTimer from "./CountDownTimer";
import { openLink } from "../utils/socialMedia";

const AnnouncementCard = ({ image, date }) => {
  return (
    <div
      className="relative w-full h-full rounded-xl overflow-hidden shadow-lg"
      onClick={() => {
        openLink(
          "https://gleam.io/LK8qr/-win-5000-in-casino-coins-in-our-exclusive-airdrop-"
        );
      }}
    >
      <img
        src={image}
        alt="Referring contest"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 flex items-end justify-end">
        <div className="bg-white bg-opacity-10 backdrop-blur-md px-1.5 rounded-md">
          <CountDownTimer date={date} />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementCard;
