import { useInitData } from "@vkruglikov/react-telegram-web-app";

const UnauthorizedScreen = () => {
  const [, initData] = useInitData();
  if (initData) {
    return (
      <div className="flex flex-col text-center items-center justify-center h-screen">
        <h1 className="font-semibold text-lg">An error occurred</h1>
        <p className="text-sm">
          Sorry for the inconvenience. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col text-center items-center justify-center h-screen">
      <h1 className="font-semibold text-lg">Unauthorized</h1>
      <p className="text-sm">
        You are not authorized to view this page. Ensure you are opening the app
        through Telegram.
      </p>
    </div>
  );
};

export default UnauthorizedScreen;
