import React, { Suspense, useEffect } from "react";
import ReflinkCard from "../components/ReflinkCard";
import GameCard from "../components/GameCard";
import pokerCard from "../assets/images/poker_card.png";
import diceCard from "../assets/images/dice_card.png";
import slotGameCard from "../assets/images/slots_card.png";
import rouletteCard from "../assets/images/roulette_card.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../components/Animations.css";
import "./SlotsScreen.css";
import ScaleUpAnimation from "../animations/ScaleUpAnimation";
import AnnouncementCard from "../components/AnnouncementCard";
import gleamContestBanner from "../assets/images/gleam_contest_banner.jpeg";
function HomeScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("scrollToReflink")) {
      document.getElementById("reflink-card")?.scrollIntoView();
    }
  }, []);

  return (
    <Suspense>
      <ScaleUpAnimation>
        <div className="flex flex-col justify-center items-center gap-3 mt-[17.8vw] w-full px-5 pb-32 overflow-y-auto">
          <AnnouncementCard
            date={Date.parse("2024-12-10T21:59:59Z")}
            image={gleamContestBanner}
          />
          <div className="flex flex-row justify-between gap-3 w-full mt-2">
            <GameCard
              onClick={() => navigate("/game/slots")}
              imageSrc={slotGameCard}
              title="Slots"
              alt="Slot game card"
              shadowColor="#03002E"
            />
            <GameCard imageSrc={diceCard} title="Dice" alt="Dice card" />
          </div>

          <div className="flex flex-row justify-between gap-3 w-full">
            <GameCard
              imageSrc={rouletteCard}
              title="Roulette"
              alt="Roulette card"
            />
            <GameCard imageSrc={pokerCard} title="Poker" alt="Poker card" />
          </div>
          <ReflinkCard />
          <button
            onClick={() => navigate("/terms-of-use")}
            className="opacity-30 text-xs underline"
          >
            Terms of use
          </button>
        </div>
      </ScaleUpAnimation>
    </Suspense>
  );
}

export default HomeScreen;
