import UpdateCard from "../components/UpdateCard";

const NotificationsScreen = () => {
  return (
    <div className="mt-24 px-5">
      <div
        className="bg-[#130960] w-full rounded-xl overflow-hidden flex flex-col items-center p-3"
        style={{
          height: "calc(100vh - 17.5rem)",
        }}
      >
        <div className="gradient-text agbalumo-regular text-3xl mb-6">
          What's new?
        </div>
        <div className="flex flex-col gap-3 items-center justify-start text-sm w-full overflow-y-auto">
          <UpdateCard
            text="Started an exciting 30% deposit bonus promotion!"
            date="15-11-2024"
          />
          <UpdateCard
            text="Gleam code rewards have been added."
            date="14-11-2024"
          />
          <UpdateCard
            text="Real time notifications for referrers have been introduced."
            date="10-11-2024"
          />
          <UpdateCard
            text="Slots bet size calculations has been improved."
            date="09-11-2024"
          />
          <UpdateCard
            text="Improved wallet screen tabs UI."
            date="08-11-2024"
          />
          <UpdateCard
            text="Enhanced deposit experience during gameplay."
            date="07-11-2024"
          />
          <UpdateCard
            text="An exciting $600 referring competition has been announced!"
            date="06-11-2024"
          />
          <UpdateCard
            text="Top referrers ranking has been added."
            date="06-11-2024"
          />
          <UpdateCard
            text="Improved both balance indicators UX."
            date="05-11-2024"
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationsScreen;
